<template>
  <div class="layout-header-section">
    <div class="header-label col-lg-6 col-12">Virtual Numbers</div>
  </div>
  <div class="custom-ultima-datatable" style="height: calc(100vh - 268px)">
    <DataTable :value="virtualNumberList" :scrollable="true" scrollHeight="flex" :paginator="false" :lazy="true"
      :rowHover="true" :totalRecords="totalRecords"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      :loading="loading">
      <template v-if="!loading" #empty>No records found.</template>
      <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
      <Column field="mobileno" header="Mobile Number" headerStyle="width: 20%" bodyStyle="width: 20%">
        <template #body="{ data }">
          <div class="w-100">
            <div class="d-flex align-items-center justify-content-between">
              <div class="label-subheading">
                {{
      data.mobile_one != "" && data.mobile_one != null
        ? "+91 " + data.mobile_one
        : "No Number"
    }}
              </div>
              <div>
                <button type="button" title="Edit" class="btn custom-outline-view-btn ms-4"
                  @click="editForwardedNumberModalOpen(data)">
                  <i class="pi pi-pencil call-btn-color"></i>
                </button>
              </div>
            </div>
          </div>
        </template>
      </Column>
      <Column field="virtualnumber" header="Virtual Number" headerStyle="width: 50%" bodyStyle="width: 50%">
        <template #body="{ data }">
          <div class="label-subheading">{{ "0" + data.cn2 }} <span v-if="data.cn13 == 2">(CB)</span></div>
        </template>
      </Column>
      <Column field="status" header="Status" headerStyle="width: 20%" bodyStyle="width: 20%">
        <template #body="{ data }">
          <div class="label-subheading">
            <span class="appointment-badge status-scheduled" v-if="data.cn16 == 1">Active</span>
            <span class="appointment-badge status-cancelled" v-if="data.cn16 == 2">Notice Period</span>
            <span class="appointment-badge status-cancelled" v-if="data.cn16 == 3">Request For Delete</span>
            <span class="appointment-badge status-cancelled" v-if="data.cn16 == 4">Release</span>
          </div>
        </template>
      </Column>
      <Column field="action" header="Action" class="justify-content-center" headerStyle="width: 10%"
        bodyStyle="width: 10%">
        <template #body="{ data }">
          <button type="button" class="btn custom-view-detail-btn" @click="redirectToVirtualSetting(data)">
            Settings
          </button>
        </template>
      </Column>
    </DataTable>
  </div>
  <div class="col-lg-12 col-md-12 col-12 mt-3">
    <div class="customer-label-group mb-1 d-flex align-items-center">
      <div class="from-label-value text-danger">Important Notes :</div>
    </div>
    <div class="customer-label-group d-flex align-items-center">
      <ol class="list-group list-group-flush">
        <li class="form-label list-group-item">1. Please do not display or publish CALLBACK NUMBER anywhere, these
          numbers
          can be changed without any prior notice due to TRAI guidelines.</li>
        <li class="form-label list-group-item">2. You have to forward the mobile number to the provided virtual number.
        </li>
      </ol>
    </div>
  </div>
  <!-- confirmation modal start here -->
  <div class="modal-mask" v-if="primaryinputboxshow">
    <div class="modal-dialog modal-xs modal-dialog-centered custom-modal-outer confirm-dialog-modal">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Change Mobile Number</h5>
          <button type="button" class="btn-close" @click="editForwardedNumberModalClose()"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label class="form-label">Mobile No.</label>
                <div class="input-group custom-input-group">
                  <div class="col-lg-4 col-md-4 col-12">
                    <Multiselect v-model="countrycode" :options="countryCodeList" :searchable="true" label="label"
                      placeholder="Select" disabled class="multiselect-custom custom-title-multiselect"
                      :canClear="false" :closeOnSelect="true" :object="true" noOptionsText="No Result"
                      :canDeselect="false" />
                  </div>
                  <div class="col-lg-8 col-md-8 col-12">
                    <input type="text" v-model="forwardingnumberone"
                      class="form-control custom-form-search-group text-capitalize" placeholder="Enter Mobile No"
                      maxlength="10" @keypress="onlyNumber" autocomplete="off" @paste="onPasteMobile" />
                  </div>
                </div>
                <div class="custom-error" v-if="v$.forwardingnumberone.$error">
                  {{ v$.forwardingnumberone.$errors[0].$message }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 text-end">
            <button type="button" @click="saveForwardedNumberOne()" class="btn filter-apply-btn" :disabled="showloader"
              style="width: 70px;">
              <span v-if="!showloader">Submit</span>
              <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="showloader">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- confirmation modal end here -->
</template>
<script>
import ApiService from "../../service/ApiService";
import useValidate from "@vuelidate/core";
import { minLength, helpers } from "@vuelidate/validators";
export default {
  data() {
    return {
      v$: useValidate(),
      virtualNumberList: null,
      totalRecords: 0,
      loading: false,
      forwardingnumberone: "",
      primaryinputboxshow: false,
      forwarded_number_detail: "",
      countrycode: { value: 101, label: "+91" },
      countryCodeList: [],
      showloader: false,
    };
  },
  ApiService: null,

  created() {
    this.ApiService = new ApiService();
  },
  validations() {
    return {
      forwardingnumberone: { minLength: helpers.withMessage('Enter vaild Mobile No.', minLength(10)) },
    };
  },

  mounted() {
    this.getvirtualnumberslist();
  },

  methods: {
    getvirtualnumberslist(e) {
      this.loading = true;
      this.ApiService.getvirtualnumberslist(e).then((data) => {
        if (data.status == 200) {
          this.virtualNumberList = data.record;
          this.totalRecords = data.count;
          this.loading = false;
        } else {
          this.loading = false;
          this.virtualNumberList = null;
          this.totalRecords = 0;
        }
      });
    },

    addupdatesetting(e) {

      var fields = {};
      fields["number_id"] = e.cn1;
      fields["settingid"] = e.ca1;
      this.ApiService.addupdatesetting(fields).then((data) => {
        if (data.status == 200) {
          this.addressSettingList = data.record;
        }
      });
    },

    redirectToVirtualSetting(e) {
      this.addupdatesetting(e);


      this.ca1 = e.ca1 == null ? 0 : e.ca1;
      let routePath = "/cloudtelephony/virtualnumbers/set";
      this.$router.push(`${routePath}/${btoa(e.cn1)}/${btoa(this.ca1)}`);
    },

    editForwardedNumberModalOpen(e) {
      this.primaryinputboxshow = true;
      this.forwarded_number_detail = e;
      this.forwardingnumberone = e.mobile_one;
    },
    editForwardedNumberModalClose() {
      this.primaryinputboxshow = false;
      this.forwarded_number_detail = '';
      this.forwardingnumberone = '';
      setTimeout(() => {
        this.v$.$reset();
      }, 0);
    },

    saveForwardedNumberOne() {
      this.v$.$validate();
      var fields = {};
      fields["mobile_number"] = this.forwardingnumberone;
      fields["type"] = 1;
      fields["numberDetails"] = this.forwarded_number_detail;
      if (!this.v$.$error) {
        this.showloader = true;
        this.ApiService.savemobilenumber(fields).then((data) => {
          if (data.success == true) {
            var successMsg = data.message;
            this.$toast.open({
              message: successMsg,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            this.forwarded_number_detail = "";
            this.forwardingnumberone = "";
            this.primaryinputboxshow = false;
            this.showloader = false;
            this.getvirtualnumberslist();
          } else {
            this.primaryinputboxshow = true;
            this.showloader = false;
            var errorMsg = data.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
          }
        });
      } else {
        this.$error;
      }
    },
    onPasteMobile(event) {
      let mobvalue = event.clipboardData.getData('text');
      if (/^[0-9]+$/.test(mobvalue)) return true;
      else event.preventDefault();
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
  },
};
</script>
<style scoped>
.custom-outline-save-btn {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  border-radius: 4px;
  padding: 7.5px 9px;
  line-height: 10px;
}

.custom-outline-save-btn .save-btn-color {
  color: #2ca270;
  font-size: 13px;
}

.customer-label-group {
  margin-bottom: 16px;
}

.customer-label-group .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}

.customer-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
}
</style>